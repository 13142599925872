import $ from 'jquery';
export default function hintHandler() {
    $('.hint-open').on('click', function(e){
        let siblingsHint = $(this).siblings('.hint');
        siblingsHint.toggleClass('show');
        $('.hint').not(siblingsHint).removeClass('show');
    });
    const hintSelectors= {
        btn:'.hint-open', 
        menu: '.hint', 
        open: 'show'
    }
    $(document).mouseup(function (e){
        missClickHandler(hintSelectors, e);
    });
}

function missClickHandler(refs, event){
    const { btn, menu, open} =  refs;
    if (!$(btn).is(event.target) && $(menu).has(event.target).length === 0) {
        $(menu).removeClass(open);
    }
}
