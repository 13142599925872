import '../scss/main.scss';

import $ from 'jquery';
import { validate } from 'jquery-validation';
import faqHandler from './faq';
import hintHandler from './hint';
import contactsHandler from './contacts';
import backToTopHandler from './back-to-top';

faqHandler();
hintHandler();
backToTopHandler();

if(!$('body').hasClass('contacts-page')){
    
    $('.modal-open').each(function(){
        $(this).on('click', function(){
            modalOpen();
        });
    });
    
    $('.modal__backdrop').on('click', function(e){
        if(e.currentTarget == e.target){
            modalClose();
            modalClean();
        }
    });
    
    $('#modal__close-btn').on('click', function(e){
        modalClose();
        modalClean();
    });
    
    $('#form').validate();
    
    // recaptcha v3
    grecaptcha.ready(function () {
        grecaptcha.execute('6LdVn_spAAAAAGQFkgFeRRb52n792ZQG5X7RyOaR', { action: 'homepage' })
            .then(function (token) {
                $('#g-recaptcha-response').val(token);
            });
    });
    
    let captcha_key_site_v2 = '6LfhnvspAAAAAEYjP1CzxMNm6kttQp9U-EyQYCjW'; // значения ключей из файла config.php
    let captcha_key_site_v3 = '6LdVn_spAAAAAGQFkgFeRRb52n792ZQG5X7RyOaR'; // значения ключей из файла config.php
    let widgetCaptcha = false // id виджета, на случай, если будет использовано несколько форм на странице
    let form = $('#form');
    
    $('#modal__btn').on('click', function(e){
        e.preventDefault();
        if($('#form').valid()){
            form.submit();
        }
    });
    
    function sendForm() {
        let data = form.serialize()
        if($('#form').valid()){
            $('.loader').addClass('show');
            $('#modal__btn').prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: '/includes/send_form.php',
                data: data,
                dataType: 'json',
                success: function (response, textStatus) {
                    $('.loader').removeClass('show');
                    $('#modal__btn').prop('disabled', false);
                    if(!response.hasOwnProperty('error')){
                        if(textStatus === "success" && response.status === 'sent'){
                            modalClean();
                            modalClose();
                            emailSentSuccess();
                        } else {
                            console.log('Response:', response);
                            modalClean();
                            modalClose();
                            emailSentError();
                        }
                    } else if(response.hasOwnProperty('error')){
                        // если была ошибка капчи, сбрасываем капчку v2 при наличии
                        if (widgetCaptcha !== false) {
                            grecaptcha.reset(widgetCaptcha)
                        }
                        // если ошибка была в версии v3, показываем видимую капчу v2
                        // widgetCaptcha - идентификатор, т.е. можно рендерить и управлять несколькими штуками
                        if (response.error === 'fall_captcha_v3' && !widgetCaptcha) {
                            widgetCaptcha = grecaptcha.render('captcha', {
                                'sitekey': captcha_key_site_v2,
                                'callback': afterValidation
                            });
                            $('.captcha_wrap').show();
                        }
    
                        if (response.error === 'fall_captcha' || response.error === 'fall_captcha_v2') {
                            console.log('Response:', response);
                            modalClean();
                            modalClose();
                            emailSentError();
                        }
                    } else{
                        console.log('Error Status:', textStatus);
                        console.log('Response:', response);
                        modalClean();
                        modalClose();
                        emailSentError();
                    }
                },
                error: function(responce, textStatus, errorThrown) {
                    $('.loader').removeClass('show');
                    $('#modal__btn').prop('disabled', false);
                    console.log('Error Status:', textStatus);
                    console.log('Error Thrown:', errorThrown);
                    console.log('Response:', responce);
                    modalClean();
                    modalClose();
                    emailSentError();
                }
            });
        }
    }
    
    // функция-колбек добавляет полученный токен второй версии капчи в скрытое поле формы
    function afterValidation(token) {
        form.find('input[name="captcha_token_v2"]').val(token);
        form.submit();
    }
    
    // назначаем событие на попытку отправки формы,
    // если гугловый скрипт добавляем токен версии три в скрытое поле и пробуем отправить форму
    form.submit(function (e){
        e.preventDefault()
        if (typeof grecaptcha != 'undefined' && typeof captcha_key_site_v3 != 'undefined') {
            grecaptcha.ready(function () {
                grecaptcha.execute(captcha_key_site_v3, {action: 'submit'}).then(function (token) {
                    if(token) {
                        form.find('input[name="captcha_token_v3"]').val(token);
                        sendForm();
                    }
                })
            })
        }
    })
    
    function modalClean(){
        $('#modal__name').val('');
        $('#modal__email').val('');
        $('#modal__message').val('');
    };
    
    function modalOpen(){
        $('.modal__backdrop').addClass('show');
        $('body').css('overflow', 'hidden');
    }
    
    function modalClose(){
        $('.modal__backdrop').removeClass('show');
        $('body').css('overflow', 'auto');
    }
    function emailSentSuccess(){
        $('.tooltip-success').addClass('show');
        setTimeout(() => {
            $('.tooltip-success').removeClass('show');
        }, 10000);
    }
    
    function emailSentError(){
        $('.tooltip-error').addClass('show');
        setTimeout(() => {
            $('.tooltip-error').removeClass('show');
        }, 10000);
    }

} else{
    $('.header__btn').hide();
    $('.header__link').show();
    $('.header__nav-item-button>button').css('display', 'none');
    $('.header__nav-item-button>a').css('display', 'flex');
   
    contactsHandler();
}
